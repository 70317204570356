import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {StatisticsWidget5} from '../../../_metronic/partials/widgets'
import {KTIcon} from '../../../_metronic/helpers'

const DashboardPage = () => {
  const [adoData, setAdoData] = useState({})
  const [updated, setUpdated] = useState(false)
  useEffect(() => {
    fetch('https://status.dev.azure.com/_apis/status/health?api-version=7.1-preview.1')
      .then((response) => response.json())
      .then((data) => {
        let status = 1
        let result = {}

        data.services.forEach((service) => {
          service.geographies.forEach((geography) => {
            if (geography.id === 'EU') {
              if (geography.health !== 'healthy') {
                status = 0
              }
              result[service.id] = geography.health
            }
          })
        })

        result['status'] = status

        console.log(result)
        setAdoData(result)
        setUpdated(true)
      })
      .catch((error) => console.error(error))

    console.log(adoData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={[]} description='#XRS-45670'>
        Dashboard
      </PageTitle>
      {/* begin::Row */}
      {updated ? (
        <>
          <div className={`card bg-info hoverable card-xl-stretch mb-5`}>
            <div className='card-body text-center'>
              <KTIcon iconName='microsoft' className={`text-white fs-5x ms-n1`} />
              <div style={{fontSize: '40px'}} className={`text-white fw-bold mb-2 mt-5`}>
                Doet Azure DevOps het al?
              </div>
              <div style={{fontSize: '60px'}} className={`fw-semibold text-white`}>
                {adoData['status'] === 1 ? <>Ja</> : <>Nee</>}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={`card bg-info hoverable card-xl-stretch mb-5`}>
            <div className='card-body text-center'>
              <KTIcon iconName='microsoft' className={`text-white fs-5x ms-n1`} />
              <div style={{fontSize: '40px'}} className={`text-white fw-bold mb-2 mt-5`}>
                Doet Azure DevOps het al?
              </div>
              <div style={{fontSize: '60px'}} className={`fw-semibold text-white`}>
                <span
                  style={{width: '50px', height: '50px'}}
                  className='spinner-border spinner-border-sm align-middle ms-2 fs-1'
                />
              </div>
            </div>
          </div>
        </>
      )}
      <div className='row g-5 g-xl-8 mt-5'>
        <div className='col-lg-4 col-sm-6 col-xs-12 mt-0'>
          <>
            <div
              className={`card bg-${
                adoData['Artifacts'] === 'healthy' ? 'success' : 'danger'
              } hoverable card-xl-stretch mb-5`}
            >
              <div className='card-body text-center d-flex align-items-center justify-content-center flex-column'>
                <KTIcon iconName='file' className={`text-white fs-3x`} />
                <div className={`text-white fw-bold fs-2x mb-2 mt-1`}>Artifacts</div>
              </div>
            </div>
          </>
        </div>
        <div className='col-lg-4 col-sm-6 col-xs-12 mt-0'>
          <>
            <div
              className={`card bg-${
                adoData['Boards'] === 'healthy' ? 'success' : 'danger'
              } hoverable card-xl-stretch mb-5`}
            >
              <div className='card-body text-center d-flex align-items-center justify-content-center flex-column'>
                <KTIcon iconName='trello' className={`text-white fs-3x`} />
                <div className={`text-white fw-bold fs-2x mb-2 mt-1`}>Boards</div>
              </div>
            </div>
          </>
        </div>
        <div className='col-lg-4 col-sm-6 col-xs-12 mt-0'>
          <>
            <div
              className={`card bg-${
                adoData['Core services'] === 'healthy' ? 'success' : 'danger'
              } hoverable card-xl-stretch mb-5`}
            >
              <div className='card-body text-center d-flex align-items-center justify-content-center flex-column'>
                <KTIcon iconName='android' className={`text-white fs-3x`} />
                <div className={`text-white fw-bold fs-2x mb-2 mt-1`}>Core Services</div>
              </div>
            </div>
          </>
        </div>
        <div className='col-lg-4 col-sm-6 col-xs-12 mt-0'>
          <>
            <div
              className={`card bg-${
                adoData['Other services'] === 'healthy' ? 'success' : 'danger'
              } hoverable card-xl-stretch mb-5`}
            >
              <div className='card-body text-center d-flex align-items-center justify-content-center flex-column'>
                <KTIcon iconName='devices' className={`text-white fs-3x`} />
                <div className={`text-white fw-bold fs-2x mb-2 mt-1`}>Other Services</div>
              </div>
            </div>
          </>
        </div>
        <div className='col-lg-4 col-sm-6 col-xs-12 mt-0'>
          <>
            <div
              className={`card bg-${
                adoData['Pipelines'] === 'healthy' ? 'success' : 'danger'
              } hoverable card-xl-stretch mb-5`}
            >
              <div className='card-body text-center d-flex align-items-center justify-content-center flex-column'>
                <KTIcon iconName='route' className={`text-white fs-3x`} />
                <div className={`text-white fw-bold fs-2x mb-2 mt-1`}>Pipelines</div>
              </div>
            </div>
          </>
        </div>
        <div className='col-lg-4 col-sm-6 col-xs-12 mt-0'>
          <>
            <div
              className={`card bg-${
                adoData['Repos'] === 'healthy' ? 'success' : 'danger'
              } hoverable card-xl-stretch mb-5`}
            >
              <div className='card-body text-center d-flex align-items-center justify-content-center flex-column'>
                <KTIcon iconName='folder' className={`text-white fs-3x`} />
                <div className={`text-white fw-bold fs-2x mb-2 mt-1`}>Repos</div>
              </div>
            </div>
          </>
        </div>
        <div className='col-lg-12 mt-0'>
          <>
            <div
              className={`card bg-${
                adoData['Test Plans'] === 'healthy' ? 'success' : 'danger'
              } hoverable card-xl-stretch mb-5`}
            >
              <div className='card-body text-center d-flex align-items-center justify-content-center flex-column'>
                <KTIcon iconName='test-tubes' className={`text-white fs-3x`} />
                <div className={`text-white fw-bold fs-2x mb-2 mt-1`}>Test Plans</div>
              </div>
            </div>
          </>
        </div>
      </div>
      {/* end::Row */}
    </>
  )
}

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
